import React from "react";
import EventGallery from "../components/events/EventsGallery";

export default function EventsPage() {
	return (
		<div>
			<EventGallery />
		</div>
	);
}
